import React, { createContext, useContext, useState } from "react";

const PolicyModalContext = createContext();

export const usePolicyModal = () => useContext(PolicyModalContext);

const PolicyModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  function closePolicyModal() {
    setIsOpen(false);
    document.body.style.overflowY = "auto";
  }

  function openPolicyModal() {
    setIsOpen(true);
    document.body.style.overflowY = "hidden";
  }

  const value = {
    isOpen,
    closePolicyModal,
    openPolicyModal,
  };

  return (
    <PolicyModalContext.Provider value={value}>
      {children}
    </PolicyModalContext.Provider>
  );
};

export default PolicyModalProvider;
