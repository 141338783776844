import React, { createContext, useContext, useState } from "react";

const ConfirmModal = createContext();

export const useConfirmModal = () => useContext(ConfirmModal);

const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    document.body.classList.add("blur");
  };

  const closeModal = () => {
    setIsOpen(false);
    document.body.classList.remove("blur");
  };

  const value = {
    isOpen,
    openModal,
    closeModal,
  };

  return (
    <ConfirmModal.Provider value={value}>{children}</ConfirmModal.Provider>
  );
};

export default ModalProvider;
