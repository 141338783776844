function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const validateInfo = (values) => {
  let errors = {};

  // Name
  if (!values.Name__c.trim()) {
    errors.Name__c =
      values.language === "en_US" ? "Name is required" : "مطلوب اسم";
  }

  // Surname
  if (!values.Surname__c.trim()) {
    errors.Surname__c =
      values.language === "en_US" ? "Surname is required" : "اللقب مطلوب";
  }

  // Email
  if (!values.Email__c.trim()) {
    errors.Email__c =
      values.language === "en_US"
        ? "Email is required"
        : "البريد الالكتروني مطلوب";
  } else if (!emailIsValid(values.Email__c)) {
    errors.Email__c =
      values.language === "en_US"
        ? "Email is not valid"
        : "البريد الإلكتروني غير صالح";
  }

  return errors;
};

export default validateInfo;
