import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./globals.scss";
import DataContextProvider from "./contexts/DataContext";
import ModalProvider from "./contexts/ConfirmModal";
import PolicyModalProvider from "./contexts/PolicyModal";
import LanguageContextProvider from "./contexts/LanguageContext";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <LanguageContextProvider>
      <PolicyModalProvider>
        <DataContextProvider>
          <ModalProvider>
            <Router>
              <App />
            </Router>
          </ModalProvider>
        </DataContextProvider>
      </PolicyModalProvider>
    </LanguageContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
