import { usePolicyModal } from "../../contexts/PolicyModal";
import { useLanguageContext } from "../../contexts/LanguageContext";
import { useDataContext } from "../../contexts/DataContext";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";

import styles from "./PolicyModal.module.scss";

const PolicyModal = () => {
  const { lang } = useLanguageContext();
  const { isOpen, closePolicyModal } = usePolicyModal();
  const { placedBySections, getSafe } = useDataContext();
  const divRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(e) {
      if (isOpen && divRef.current && !divRef.current.contains(e.target)) {
        closePolicyModal();
      }
      return;
    }

    document.addEventListener("click", handleClickOutside);

    return () => document.removeEventListener("click", handleClickOutside);
  }, [isOpen]);

  const title = getSafe(
    () => placedBySections[lang].Privacy_Policy[0].contentNodes.Title.value
  );

  const policy = getSafe(
    () => placedBySections[lang].Privacy_Policy[0].contentNodes.Policy.value
  );

  const parsedPolicy = parse(policy).toString();

  return (
    <div
      className={`${styles.modal_container} ${
        isOpen ? styles.open : styles.close
      }`}
    >
      <div className={styles.wrapper} ref={divRef}>
        <div className={styles.cross} onClick={closePolicyModal} />
        <h1>{title}</h1>
        <div className={styles.paragraph_container}>{parse(parsedPolicy)}</div>
      </div>
    </div>
  );
};

export default PolicyModal;
