import { useDataContext } from "../../contexts/DataContext";
import { useLanguageContext } from "../../contexts/LanguageContext";

import styles from "./Contact.module.scss";

const Contact = () => {
  const { instUrl, placedBySections, getSafe } = useDataContext();
  const { lang } = useLanguageContext();

  const firstTitle = getSafe(
    () => placedBySections[lang].Banner_With_CTA[0].contentNodes.Title.value
  );

  const firstCtaLink = getSafe(
    () => placedBySections[lang].Banner_With_CTA[0].contentNodes.ctaLink.value
  );

  const firstCta = getSafe(
    () => placedBySections[lang].Banner_With_CTA[0].contentNodes.cta.value
  );

  const secondTitle = getSafe(
    () => placedBySections[lang].Banner_With_CTA[1].contentNodes.Title.value
  );

  const secondCtaLink = getSafe(
    () => placedBySections[lang].Banner_With_CTA[1].contentNodes.ctaLink.value
  );

  const secondCta = getSafe(
    () => placedBySections[lang].Banner_With_CTA[1].contentNodes.cta.value
  );

  return (
    <section id="join" className={styles.contact_container}>
      <div className={styles.gifts}>
        <img
          src={`${instUrl}${placedBySections.en_US.Banner_With_CTA[0].contentNodes.bgImage.url}`}
          alt=""
        />
        <div className={styles.gifts__top}>
          <h1>{firstTitle}</h1>
        </div>
        <div className={styles.gifts__bottom}>
          <a href={firstCtaLink}>{firstCta}</a>
        </div>
      </div>

      <div className={styles.join}>
        <img
          src={`${instUrl}${placedBySections.en_US.Banner_With_CTA[1].contentNodes.bgImage.url}`}
          alt=""
        />
        <div className={styles.join__top}>{secondTitle}</div>
        <div className={styles.join__bottom}>
          <a href={secondCtaLink}>{secondCta}</a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
