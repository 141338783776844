import { createContext, useContext, useState, useEffect } from "react";

const DataContext = createContext();

export const useDataContext = () => useContext(DataContext);

const DataContextProvider = ({ children }) => {
  const [placedBySections, setPlacedBySections] = useState({});
  const [instUrl, setInstUrl] = useState("");

  useEffect(() => {
    fetch("/.netlify/functions/server/server")
      .then((res) => res.json())
      .then((data) => {
        const instanceUrl = data.instance_url;
        const placedBySections = data.placedBySections;

        setInstUrl(instanceUrl);
        setPlacedBySections(placedBySections);
      });
  }, []);

  function getSafe(fn) {
    try {
      return fn();
    } catch (e) {
      return "";
    }
  }

  const value = { instUrl, placedBySections, getSafe };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export default DataContextProvider;
