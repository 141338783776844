import Header from "./components/header/Header.component";
import Hero from "./components/hero/Hero.component";
import About from "./components/about/About.component";
import Location from "./components/location/Location.component";
import SignUp from "./components/signup/Signup.component";
import Contact from "./components/contact/Contact.component";
import Modal from "./components/modal/Modal.component";
import Footer from "./components/footer/Footer.component";
import PolicyModal from "./components/policyModal/PolicyModal.component";
import { useLanguageContext } from "./contexts/LanguageContext";
import { useDataContext } from "./contexts/DataContext";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function App() {
  const { placedBySections } = useDataContext();
  const { lang } = useLanguageContext();
  let history = useHistory();
  const params = new URLSearchParams();

  useEffect(() => {
    if (Object.keys(placedBySections).length && window.location.hash) {
      const element = document.querySelector(window.location.hash);
      element.scrollIntoView();
    }
  }, [placedBySections]);

  useEffect(() => {
    if (lang === "ar") {
      document.querySelector("html").setAttribute("dir", "rtl");
    } else {
      document.querySelector("html").setAttribute("dir", "ltr");
    }
  }, [lang]);

  useEffect(() => {
    if (placedBySections[lang]) {
      document.title = placedBySections[lang].Title_Description[0].title;
    }
  }, [placedBySections]);

  useEffect(() => {
    params.append("lang", lang);
    history.push({ search: params.toString(), hash: window.location.hash });
  }, [lang, history]);

  if (Object.keys(placedBySections).length === 0) return null;

  return (
    <>
      <Header />
      <Hero />
      <About />
      <Location />
      <SignUp />
      <Contact />
      <Modal />
      <Footer />
      <PolicyModal />
    </>
  );
}

export default App;
