import { useDataContext } from "../../contexts/DataContext";

import hero from "./Hero.module.scss";

const Hero = () => {
  const { instUrl, placedBySections } = useDataContext();

  const image = `${instUrl}${placedBySections.en_US.HeroBanner[0].contentNodes.heroBanner.url}`;

  return (
    <section className={hero.container}>
      <div className={hero.cover} />
      <div className={hero.img_container}>
        <img src={image} alt="hero" />
      </div>
    </section>
  );
};

export default Hero;
