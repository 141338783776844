import { useEffect, useState } from "react";

import styles from "./Footer.module.scss";

const Footer = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const checkOffSetY = () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    document.addEventListener("scroll", checkOffSetY);

    return () => {
      return document.removeEventListener("scroll", checkOffSetY);
    };
  }, [showButton]);

  function backToTop() {
    window.scrollTo({ top: 0 });
  }

  return (
    <footer className={styles.footer}>
      <div className={styles.copyright}>© Neuhaus</div>

      {showButton && (
        <div className={styles.backtotop} onClick={backToTop}>
          <div className={styles.arrow} />
        </div>
      )}
    </footer>
  );
};

export default Footer;
