import { useLanguageContext } from "../../contexts/LanguageContext";
import parse from "html-react-parser";
import { useDataContext } from "../../contexts/DataContext";

import about from "./About.module.scss";

const About = () => {
  const { instUrl, placedBySections, getSafe } = useDataContext();
  const { lang } = useLanguageContext();

  const title = getSafe(
    () => placedBySections[lang].news[0].contentNodes.title.value
  );

  const body = getSafe(
    () => placedBySections[lang].news[0].contentNodes.body.value
  );
  const string = parse(body).toString();

  const image = `${instUrl}${placedBySections.en_US.news[0].contentNodes.bannerImage.url}`;

  return (
    <>
      <div id="about" className={about.about} />
      <section  className={about.container}>
        <h3>{title}</h3>
        <div className={about.history}>
          <div className={about.text_container}>{parse(string)}</div>
          <div className={about.history__img}>{<img src={image} />}</div>
        </div>
      </section>
    </>

  );
};

export default About;
