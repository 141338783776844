import { useConfirmModal } from "../../contexts/ConfirmModal";
import { useDataContext } from "../../contexts/DataContext";
import { useLanguageContext } from "../../contexts/LanguageContext";
import parse from "html-react-parser";

import styles from "./Modal.module.scss";

const Modal = () => {
  const { closeModal, isOpen } = useConfirmModal();
  const { placedBySections, getSafe } = useDataContext();
  const { lang } = useLanguageContext();
  const modalClass = !isOpen ? styles.close : "";

  const title = getSafe(
    () =>
      placedBySections[lang].Subscribe_Form_Details[0].contentNodes
        .confirmationTitle.value
  );

  const altTitle = getSafe(
    () =>
      placedBySections[lang].Subscribe_Form_Details[0].contentNodes
        .confirmationAltTitle.value
  );

  const description = parse(
    getSafe(
      () =>
        placedBySections[lang].Subscribe_Form_Details[0].contentNodes
          .confirmationDesc.value
    )
  );

  return (
    <div className={`${styles.modal_container} ${modalClass}`}>
      <div className={styles.wrapper}>
        <div className={styles.cross} onClick={closeModal} />
        <h1>{title}</h1>
        <div className={styles.envelop} />
        <p className={styles.thank}>{altTitle}</p>
        <p className={styles.offer}>{description}</p>
      </div>
    </div>
  );
};

export default Modal;
