import { useDataContext } from "../../contexts/DataContext";
import { useState, useRef, useEffect } from "react";
import { useLanguageContext } from "../../contexts/LanguageContext";
import arabicLogo from "../../assets/img/ar_logo.svg";
import englishLogo from "../../assets/img/enUS_logo.svg";

import styles from "./Header.module.scss";
import { useHistory } from "react-router-dom";

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const { placedBySections } = useDataContext();
  const activeId = isActive ? "active" : "";
  const divRef = useRef(null);
  const { lang, changeLang } = useLanguageContext();

  const changeMenuState = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    // Check if click is outside of the dropdown
    const clickedOutside = (e) => {
      if (isActive && divRef.current && !divRef.current.contains(e.target)) {
        //outside click
        setIsActive(false);
      }
      // inside click
      return;
    };

    document.addEventListener("click", clickedOutside);

    // Handle overlay
    if (isActive) {
      document.body.classList.add("overlay");
    } else {
      document.body.classList.remove("overlay");
    }

    return () => {
      document.removeEventListener("click", clickedOutside);
    };
  }, [isActive]);

  const linkArr =
    placedBySections[lang].Navigation || placedBySections.en_US.Navigation;

  const logo = lang === "ar" ? arabicLogo : englishLogo;

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbar__container}>
        <div
          className={styles.logo}
          style={{
            backgroundImage: `url(${logo})`,
          }}
        />
        <div className={styles.link_container}>
          <ul className={styles.links}>
            {linkArr.map((item: any) => (
              <li key={item.contentNodes.sortingID.value}>
                <a href={item.contentNodes.url.value}>
                  {item.contentNodes.Title.value}
                </a>
              </li>
            ))}
          </ul>
          <div className={styles.language}>
            <select value={lang} name="language" onChange={changeLang}>
              <option value="ar">AR</option>
              <option value="en_US">EN</option>
            </select>
          </div>
        </div>

        <div className={styles.menu} onClick={changeMenuState}>
          <div id={activeId} className={styles.hamburger} />
        </div>
      </div>
      <div
        style={{
          transform: `${isActive ? "translateY(0)" : "translateY(-140%)"}`,
        }}
        className={styles.dropdown}
        ref={divRef}
      >
        <ul className={styles.dropdown__list}>
          {linkArr.map((link: any) => (
            <li
              key={link.contentNodes.sortingID.value}
              onClick={changeMenuState}
            >
              <a href={link.contentNodes.url.value}>
                {link.contentNodes.Title.value}
              </a>
            </li>
          ))}
        </ul>

        <div className={styles.mobile_lang}>
          <select
            name="language"
            value={lang}
            onChange={(e) => {
              changeLang(e);
              changeMenuState();
            }}
          >
            <option value="ar">AR</option>
            <option value="en_US">EN</option>
          </select>
        </div>
      </div>
    </nav>
  );
};

export default Header;
