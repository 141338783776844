import { useConfirmModal } from "../../contexts/ConfirmModal";
import { useDataContext } from "../../contexts/DataContext";
import { useLanguageContext } from "../../contexts/LanguageContext";
import { usePolicyModal } from "../../contexts/PolicyModal";
import { useEffect, useState } from "react";
import axios from "axios";
import parse from "html-react-parser";
import spinnerAnimation from "../../assets/img/spinner3.svg";
import { useForm, valueObj } from "./useForm";
import validate from "./validateInfo";

import styles from "./Signup.module.scss";

const SignUp = () => {
  const { values, setValues, handleChange, errors, setErrors } = useForm();
  const { openModal } = useConfirmModal();
  const { instUrl, placedBySections, getSafe } = useDataContext();
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState("");
  const { lang } = useLanguageContext();
  const { openPolicyModal } = usePolicyModal();

  useEffect(() => {
    setValues({ ...valueObj, language: lang, Language__c:(lang === "en_US") ? "en" : lang , Gender__c: values.Gender__c });
    setErrors({});
    setError("");
  }, [lang]);

  useEffect(() => {
    if (
      Object.keys(errors).length === 0 &&
      values.Name__c &&
      values.Surname__c &&
      values.Email__c
    ) {
      const { language, ...rest } = values;
      setSpinner(true);
      axios
        .post("/.netlify/functions/server/server/api/subscribe", rest)
        .then((res: any) => {
          if (res.data.isSuccess) {
            openModal();
          } else {
            setError(res.data.details[0].message);
          }
        })
        .catch(() => setError("Something went wrong"))
        .finally(() => setSpinner(false));
    } else {
      setSpinner(false);
    }
  }, [errors]);

  const title = getSafe(
    () =>
      placedBySections[lang].Subscribe_Form_Details[0].contentNodes.Title.value
  );

  const bannerImage = `${instUrl}${placedBySections.en_US.Subscribe_Form_Details[0].contentNodes.mainBanner.url}`;

  const button = getSafe(
    () =>
      placedBySections[lang].Subscribe_Form_Details[0].contentNodes.button.value
  );

  const policyText = parse(
    getSafe(
      () =>
        placedBySections[lang].Subscribe_Form_Details[0].contentNodes.policyText
          .value
    )
  ).toString();

  const submitHandler = (e) => {
    e.preventDefault();
    setErrors(validate(values));
    setError("");
  };

  return (
    <section id="subscribe" className={styles.signup}>
      <div className={styles.img_container}>
        <img src={bannerImage} alt="" />
      </div>
      <div className={styles.form_container}>
        <h2>{parse(title)}</h2>

        <form className={styles.form} onSubmit={submitHandler}>
          <div className={styles.input_container}>
            <div className={styles.input}>
              <input
                type="text"
                placeholder={`* ${lang === "en_US" ? "Name" : "اسم"}`}
                name="Name__c"
                value={values.Name__c}
                onChange={handleChange}
              />
              {errors.Name__c && (
                <div className={styles.error}>{errors.Name__c}</div>
              )}
            </div>
            <div className={styles.input}>
              <input
                type="text"
                placeholder={`* ${lang === "en_US" ? "Surname" : "لقب"}`}
                name="Surname__c"
                value={values.Surname__c}
                onChange={handleChange}
              />
              {errors.Surname__c && (
                <div className={styles.error}>{errors.Surname__c}</div>
              )}
            </div>
            <div className={styles.input}>
              <input
                type="text"
                placeholder={`* ${
                  lang === "en_US" ? "Email" : "بريد الالكتروني"
                }`}
                name="Email__c"
                value={values.Email__c}
                onChange={handleChange}
              />
              {errors.Email__c && (
                <div className={styles.error}>{errors.Email__c}</div>
              )}
            </div>
            <div className={styles.input}>
              <input
                type="text"
                inputMode="decimal"
                placeholder={`${
                  lang === "en_US" ? "Mobile Phone" : "هاتف محمول"
                }`}
                name="Mobile_Number__c"
                value={values.Mobile_Number__c}
                onChange={handleChange}
              />
            </div>
            <select
              className={styles.gender}
              name="Gender__c"
              onChange={(e) => {
                values.Gender__c = e.target.value;
              }}
            >
              <option selected disabled>
                {lang === "en_US" ? "Select Gender" : "حدد نوع الجنس"}
              </option>
              <option value="female">
                {lang === "en_US" ? "Female" : "أنثى"}
              </option>
              <option value="male">{lang === "en_US" ? "Male" : "ذكر"}</option>
            </select>
            <div className={styles.input}>
              <input
                type="text"
                placeholder={`${lang === "en_US" ? "City" : "مدينة"}`}
              />
            </div>
            <input type="hidden" name="Country__c" value={values.Country__c} />
            <input
              type="hidden"
              name="Language__c"
              value={values.Language__c}
            />
            <input type="hidden" name="Brand__c" value={values.Brand__c} />
            <div className={styles.policy}>
              <label htmlFor="policy" onClick={openPolicyModal}>
                {parse(policyText)}
              </label>
            </div>
            {error ? (
              <div className={`${styles.error} ${styles.error__long}`}>
                {error}
              </div>
            ) : null}
            {spinner ? (
              <button
                className={`${styles.btn} ${styles.disabled}`}
                type="submit"
              >
                <div className={styles.spinner}>
                  <img src={spinnerAnimation} alt="spinner" />
                </div>
              </button>
            ) : (
              <button className={styles.btn} type="submit">
                <div>{button}</div>
              </button>
            )}
          </div>
        </form>
      </div>
    </section>
  );
};

export default SignUp;
