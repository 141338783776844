import { createContext, useContext, useEffect, useState } from "react";

const LanguageContext = createContext();

export const useLanguageContext = () => useContext(LanguageContext);

const LanguageContextProvider = ({ children }) => {
  const params = new URLSearchParams(window.location.search);
  let initialLang = params.get("lang");

  const [lang, setLang] = useState(initialLang || "ar");

  const changeLang = (e) => {
    setLang(e.target.value);
  };

  const value = { lang, setLang, changeLang };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
