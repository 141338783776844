import { useState } from "react";
import { formatPhoneNumber } from "./formatNumber";

export const valueObj = {
  Name__c: "",
  Surname__c: "",
  Mobile_Number__c: "",
  Email__c: "",
  Gender__c: "female",
  Brand__c: "Neuhaus",
  Language__c: "en",
  Country__c: "SA",
  language: "ar",
};

export const useForm = () => {
  const [values, setValues] = useState(valueObj);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "Mobile_Number__c") {
      setValues({ ...values, [name]: formatPhoneNumber(value) });
    } else {
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  return { values, setValues, handleChange, errors, setErrors };
};
