import { useDataContext } from "../../contexts/DataContext";
import { useLanguageContext } from "../../contexts/LanguageContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import styles from "./Location.module.scss";

const Location = () => {
  const { placedBySections, getSafe } = useDataContext();
  const { lang } = useLanguageContext();

  const title = getSafe(
    () =>
      placedBySections[lang].Store_Address_Section[0].contentNodes.Title.value
  );

  const locationArr =
    placedBySections[lang].Store_Address ||
    placedBySections.en_US.Store_Address;

  return (
    <>
      <div id="location" className={styles.id} />
      <section className={styles.location}>
        <h1>{title}</h1>
        <div className={styles.wrapper}>
          <div className={styles.places}>
            {locationArr.map((place) => {
              return (
                <div key={place.managedContentId} className={styles.place}>
                  <div className={styles.place__top}>
                    {place.contentNodes.StoreDate.value}
                  </div>
                  <div className={styles.place__mid}>
                    <FontAwesomeIcon className={styles.icon} icon={faCircle} />
                    <div className={styles.line} />
                  </div>
                  <div className={styles.place__bot}>
                    <a
                      href={place.contentNodes.mapURL.value}
                      className={styles.place__bot_title}
                      target="_blank"
                    >
                      {place.contentNodes.Title.value}
                    </a>
                    <span className={styles.place__bot_address}>
                      {place.contentNodes.StoreAddress.value}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Location;
